
export class TwoButtonStrings{

    static contentDescription(){
        return "Hier können Sie nähere Infos zu unserer Lösung erfahren oder direkt eine Kontaktanfrage stellen. Wir freuen uns auf Sie!"
    }

    static buttonLabelOne(){
        return "Kontaktanfrage stellen";
    }

    static buttonLabelTwo(){
        return "Mehr erfahren";
    }

    static buttonLabelMore(){
        return "Mehr erfahren";
    }

}

export class LandingHeaderStrings{

    /*
    Home
     */
    static sliderText1(){
        return "Codevance bietet eine Softwarelösung die Unternehmen hilft, anfallende Aufgaben im technischen Kundendienst," +
            " wie Installationen, Wartungen, Repraturen und Schulungen optimal zu bewältigen."
    }

    static sliderLink1(){
        return "Ticketsystem";
    }
    static sliderLink2(){
        return "Einsatzplanung";
    }

    static sliderLink6(){
        return "E-Learning";
    }

    static sliderLink5(){
        return "AR-Videokonferenzen";
    }

    static sliderLink4(){
        return "Berichtswesen";
    }

    static sliderLink3(){
        return "Aufgabenmanagement"
    }

    static sliderText2(){
        return " "
    }

    static sliderHeaderText1(){
        return "Für digitale Vorbilder im Kundenservice";
    }


    static generateRequest(){
        return "Jetzt starten";
    }

    /*
    4service
     */
    static headerText1b(){
        return "Software für Techniker, Planer und Manager";
    }
    static text1b(){
        return "Von der Erstellung des Service-Tickets, über Analyse & Zuordnungsaufgaben, bis hin zur gemeinsamen Problemlösung und Archivierung, können Sie sich auf 4Service verlassen. Lassen Sie Ihren Innen- und Außendienst effizient & effektiv zusammenarbeiten und beziehen Sie Ihre Kunden direkt mit ein: 24/7 an jedem Ort der Welt."
    }
    /*
    4connect
     */
    static headerText1c(){
        return "Schnelle Hilfe – auch ohne Servicebesuch";
    }
    static text1c(){
        return "Leiten Sie Ihre Kunden, Mitarbeiter & Kollegen an: In Echtzeit und mit einem HD-Videokonferenz System, das keine Wünsche hinsichtlich " +
            "Interaktionsmöglichkeiten auslässt. Nutzen Sie z.B. die Annotationsfunktion, um auf bestimmte Stellen aufmerksam zu machen, oder stoppen Sie" +
            " das Video einfach, um komplexe Erklärbilder zu erstellen & exportieren Sie auf Wunsch anschließend alles in eine digitale Maschinenakte."
    }
    /*
    4learning
     */
    static headerText1d(){
        return "Zur Weiterbildung von Kollegen und Kunden";
    }
    static text1d(){
        return "Erstellen Sie unterschiedlichste Trainingsmodule mit allen notwendigen Funktionen und stellen so Kunden ein digitales Schulungsprogramm zusammen." +
            " Oder sorgen Sie mit der E-Learning Plattform dafür, dass neue Mitarbeiter schneller eingelernt werden können & verfolgen Sie notwendige Zertifizierungsmaßnahmen."
    }
    /*
    4service plantafel
     */
    static headerText1e(){
        return "Einfache Planung und Disposition";
    }

    static text1e(){
        return "Planen, Verwalten und Einsehen von Einsätzen über die gesamte Mitarbeiter-Basis. Die Verfügbarkeit einer Tages-, Wochen-, oder Monatsansicht, sorgt dafür, dass sowohl kurz- als auch langristig alles im Blick bleibt."
    }

    /*
    4service ticketsystem
     */
    static headerText1f(){
        return "Anfragen im Service schneller und effektiver lösen";
    }

    static text1f(){
        return "Von der Aufnahme, Bearbeitung, Nachverfolgung bis hin zum erfolgreichen Abschluss der Anfrage, jeweils mit Verknüpfung zur digitalen Lebenslaufakte der zugehörigen Anlage.";
    }

    /*
4service reporting
 */
    static headerText1g(){
        return "Digitale Formulare, Berichte und Protokolle";
    }

    static text1g(){
        return "Wir zeigen Ihnen einen gleichermaßen effektiven, wie pragmatischen Weg heraus aus ausgedruckten Excel- und PDF-Vorlagen. Bieten Sie Ihren Service-Kollegen die Möglichkeitn, ihre Rückmeldungen zu Zeiten, Auslagen und Materialien papierlos auszufüllen und unterschreiben zu lassen.";
    }
}

import {css} from "lit";

export const SingleButtonStyles = css`
  .btn{
        background-color: var(--secondary-color);;
        color: white;
        border-radius: 2px;
        border: 2px solid var(--secondary-color);
        font-weight: 600;
        opacity: 1;
        transition: opacity 0.2s ease 0s;
        padding: 10px;
        font-size: 16px;
        height: 44px;
        min-width: 225px;
    }

  .btn:hover{
    opacity: 0.8;
    transition: opacity 0.2s;
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    .btn{
      width: 100%;
    }
  }
    
`
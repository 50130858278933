import {css} from "lit";

export const ImpressionVideoStyles = css`
  
  .outerDiv{
    padding: 0 20px;
    margin: auto;
  }
  
  .contentWrapper{
    display: flex;
    justify-content: center;
    gap: 20px;
    max-width: 1700px;
    margin: auto;
  }
    
  .card{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  
  .img{
    height: 100%;
    width: 100%;
  }
  
  .video{
  }
  
  @media only screen and (max-width: 1050px){
    .contentWrapper {
      flex-direction: column;
      align-items: center;
    }
    .card{
    max-width: none;
    }
    
    .img{
      width: 100%;
      object-fit: contain;}
  }

  @media only screen and (max-width: 480px) {
    .card{
      width: 100%;
    }

    .video{
      max-width: 200px;
      max-height: none;
    }
  }
`

<template>
  <div class="outerContainer">
    <div class="sliderContentContainer" :class="{'activeContent': selectedContent}">
      <div class="contentWrapper">
        <div class="header" v-show="selectedContent.header">{{selectedContent.header}}</div>
        <div class="subHeader" v-show="selectedContent.subHeader">{{selectedContent.subHeader}}</div>
        <div class="mainText" v-show="selectedContent.mainText">{{selectedContent.mainText}}</div>
        <blockquote class="quoteText" v-show="selectedContent.quoteText">
          <q>{{selectedContent.quoteText}}</q>
        </blockquote>
        <div v-show="selectedContent.descriptionText">{{selectedContent.descriptionText}}</div>
        <div v-show="selectedContent.footer">{{selectedContent.footer}}</div>
      </div>
      <div v-show="selectedContent.quoteAuthor" class="quote-author-wrapper">
        <span v-show="selectedContent.quoteAuthor" class="quoteAuthor">{{selectedContent.quoteAuthor}}</span><br>
        <span v-show="selectedContent.quoteCompany" class="quoteAuthor">{{selectedContent.quoteCompany}}</span><br>
        <span v-show="selectedContent.quotePosition" class="quoteAuthor">{{selectedContent.quotePosition}}</span>
      </div>
      <div v-show="selectedContent.imgSrc">
        <img :src="selectedContent.imgSrc" :alt="selectedContent.imgAlt">
      </div>
    </div>
    <!-- Tab navigation -->
    <div class="tabsContainer">
      <div v-for="tab in sliderContentData" :key="tab" :class="{'active':tab.isSelected}" class="tab" @click="this.tabNavigationClicked(tab)"></div>
    </div>
  </div>
</template>
<script>

export default {
  name: "slider-component",
  props:{
    sliderContent:{
      type: Array,
      required: true,
    }
  },

  data(){
    return{
      selectedContent: this.sliderContent[0],
      sliderContentData: this.sliderContent,
    }
  },

  methods:{
    tabNavigationClicked(content){
      this.resetSelectedTabs();
      this.selectedContent = content;

      // mark the selected tab as selected.
      const selectedContentIndex = this.sliderContentData.indexOf(content);
      this.sliderContentData[selectedContentIndex].isSelected = true;
    },

    resetSelectedTabs(){
      for (let tab of this.sliderContentData){
        tab.isSelected = false;
      }
    }
  },

}
</script>

<style scoped>
  .outerContainer{
    max-width: 1100px;
    margin: auto;
    position: relative;
  }

  .sliderContentContainer{
    display: flex;
    justify-content: space-between;
    gap: 80px;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
    opacity: 1;
  }

  .quoteAuthor{
    color:white;
    font-size: 20px;
    font-style: italic;
  }

  .quoteText{
    color: #fff;
    font-size: 2rem;
    font-weight: 500;
  }

  .contentWrapper{
    width: 60%;
    text-align: left;
  }

  img{
    width: 100%;
    object-fit: contain;
    border-radius: 2px;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
  }

  blockquote{
    margin: 0;
  }

  .tabsContainer{
    display: flex;
    justify-content: right;
    margin-top: 20px;
  }

  .tab{
    width: 60px;
    height: 8px;
    border-radius: 10px;
    display: inline-block;
    cursor: pointer;
    margin: 5px;
    border: 2px solid var(--lightgrey);
    background-size: 200% 100%;
    background-position: right bottom;
  }

  .header{
    font-weight: 400;
    font-size: 25px;
    margin: 0;
    line-height: 1.31;
    color: white;
  }

  .subHeader{
    text-align: left;
    width: 100%;
    max-width: 600px;
    margin: 0 0 20px;
    font-size: 3rem;
    font-weight: 500;
    color: white;
  }

  .mainText{
    font-size: 16px;
    color: white;
  }

  .active{
    background-color: var(--lightgrey);
  }

  @media only screen and (max-width: 1100px) {
    .sliderContentContainer{
      flex-direction: column;
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      border-radius: 2px;
      box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
    }

    .contentWrapper{
      width: 100%;
    }

    .sliderContentContainer{
      position: revert;
    }

    .outerContainer{
      position: relative;
    }
  }

</style>
<template>
  <div id="app">
    <div class="desktop">
      <navigation-bar></navigation-bar>
    </div>
    <div class="mobile">
      <mobile-nav-bar></mobile-nav-bar>
    </div>
    <router-view v-slot="{Component}">
      <transition name="slide" mode="out-in">
        <component :is="Component" :key="$route.path"></component>
      </transition>
    </router-view>
    <cookie-banner v-if="!isAccepted"></cookie-banner>
    <footer-bar></footer-bar>
    <!--<div class="desktop">
      <quick-action-sidebar @btn-one-clicked="navigate('/about-us/contact')"></quick-action-sidebar>
    </div>-->
  </div>
</template>

<script>

import "/src/components/lit-element/quick-action-sidebar/quick-action-sidebar"
import NavigationBar from "/src/components/vue/navigation-bar/navigation-bar";
import FooterBar from "./components/vue/footer-bar/FooterBar";
import MobileNavBar from "./components/vue/navigation-bar/MobileNavBar";
import CookieBanner from "@/components/lit-element/cookie-banner/cookieBanner";


export default {
  name: 'App',
  components: {CookieBanner, MobileNavBar, NavigationBar, FooterBar},
  methods:{
    navigate(route){
      this.$router.push({
        "path": route
      })
    }
  },
  computed:{
    isAccepted(){
      return localStorage.getItem("cookieBannerAccepted");
    }
  },
  created() {
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', 'de')
  }
}

</script>

<style>

/*
GENERAL
 */
body{
  margin:0;
  box-sizing: border-box;
}

#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
}

:root {
  --primary-color: #286EB0;
  --secondary-color: #012241;
  --grey: #8A8B95;
  --dark: rgb(29, 29, 29);
  --lightgrey: #f1f2f3;
  --dark-grey: #30343F;
}

.desktop {
  display: block;
}

.mobile{
  display: none;
}

h2{
  font-weight: 500;
  margin: 0;
  line-height: 1.31;
}

ul{
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

li{
  list-style: none;
}

/*
SWEETALERT
 */
.swal2-html-container, .swal2-title{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@media only screen and (max-width: 1100px) {
  .desktop {
    display: none;
  }

  .mobile{
    display: block;
  }

}

/*
animation
 */

.slide-enter-active,
.slide-leave-active{
  transition: opacity 0.5s, transform 0.5s;
}

.slide-enter-from,
.slide-leave-to{
  opacity: 0;
  transform: translate(-5%);
}
</style>

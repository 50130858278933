import {html} from "lit";
import {LitElement} from "lit";
import {ImpressionVideoStyles} from "./impression-video-styles";

export class ImpressionVideo extends  LitElement{
    constructor() {
        super();

        this.srcArray = []
    }

    static get properties(){
        return{
            srcArray: {Type: Array},
        }
    }

    static get styles(){
        return ImpressionVideoStyles
    }

    render(){
        return html`
            <div class="outerDiv">
                <div class="contentWrapper">
                    ${this.srcArray.map((entry)=>html`
                        <div class="card">
                            ${entry.video ? html`
                                <iframe class="video" width="100%" height="100%" src="${entry.video}" 
                                        title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                        allowfullscreen>
                                </iframe>
                            `: html`
                                <img class="img" src="${entry.img}" alt="${entry.alt}">
                            `}
                        </div>
                    `)}
                </div>
            </div>
        
        `}
}

customElements.define("impression-video",ImpressionVideo)

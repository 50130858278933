<template>
  <div>
    <div class="marginTop"></div>
    <landing-header-2 :srcArray="landingHeaderSrcArray"></landing-header-2>
    <div class="customerReviewsContainer">
      <h2 class="headlineHome">Im Einsatz bei erfolgreichen Mittelständlern</h2>
      <div>
        <customer-reviews :srcArray="customerReviewsData"></customer-reviews>
      </div>
    </div>
    <div class="videoViewContainer">
      <h2 class="headlineHome" style="color:white;">4Service in 60 Sekunden</h2>
      <div class="videoViewWrapper">
        <video-view :autoplay="false" :controls="true" :videoParams="videoSrcArray"></video-view>
        <div style="margin-top: 60px">
          <single-button btnText="Beratungstermin vereinbaren" @button-clicked="openPopup"></single-button>
        </div>
      </div>
    </div>
    <div class="hoverCardsContainer">
      <h2 class="headlineHome">Bringen Sie Zusammenarbeit auf ein neues Level</h2>
      <hover-cards @card-clicked="this.hoverCardClicked" :isCarousel="true" :srcArray="this.hoverCardData"></hover-cards>
    </div>
    <div class="impressionRowContainer">
      <h2 class="headlineHome">Nutzen Sie sofortige Vorteile</h2>
      <impression-row :srcArray = this.impressionRowContentArr></impression-row>
    </div>
    <div class="sliderComponentContainer">
      <slider-component :slider-content="this.sliderContent"></slider-component>
    </div>
    <div class="twoButtonContainer">
      <h2 class="headlineHome" style="margin: 0 0 40px 0;">Lernen Sie 4Service kennen</h2>
      <two-button :showButtonTwo="true" @btn-one-clicked="goToContact" @btn-two-clicked="goToSolutions"
                  :contentDescription="contentDescription" :buttonLabelOne="buttonLabelOne" :buttonLabelTwo="buttonLabelTwo">
      </two-button>
    </div>
  </div>
</template>

<script>

import "@/components/lit-element/text-icon/text-icon";
import "@/components/lit-element/primary-text/primary-text";
import "@/components/lit-element/hover-cards/hover-cards";
import "@/components/lit-element/impression-video/impression-video"

import {LandingHeaderStrings} from "/src/components/lit-element/landing-header/landing-header-strings";
import LandingImage from "/src/assets/images/home/img.png";
import landingHeader2 from "@/components/vue/landing-header-2/landing-header-2";

import iconOne from "/src/assets/images/other/kundenbindung-min.jpg"

import "/src/components/lit-element/info-icons/info-icons"

import "/src/components/lit-element/two-button/two-button"
import {TwoButtonStrings} from "@/components/lit-element/two-button/two-button-strings";

//row-elements
import "/src/components/lit-element/row-elements/row-elements";
import "/src/components/lit-element/team-member/team-member-popup/team-member-popup"

import "/src/components/lit-element/customer-reviews/customer-reviews"
import logoKlausUnion from "/src/assets/images/clients/logos/klausunion.png";
import logoAcm from "/src/assets/images/clients/logos/acm-1.png";
import logoGleason from "/src/assets/images/clients/logos/gleason.png";
import logoMae from "/src/assets/images/clients/logos/mae.png";
import logoNagel from "/src/assets/images/clients/logos/nagel.png";
import logoGehring from "/src/assets/images/clients/logos/Gehring_Logo.png";

import imgService1 from "/src/assets/images/slider/slider-service-offer/ticketsystem.png";
import imgService2 from "/src/assets/images/slider/slider-service-offer/aufgabenmanagement.png";
import imgService3 from "/src/assets/images/slider/slider-service-offer/website-einsatzplanung.png";
import imgConnect1 from "/src/assets/images/slider/slider-service-offer/videokonferenz-website-vektor.png";
import imgConnect2 from "/src/assets/images/slider/slider-service-offer/remote-website-vektor.png";
import imgConnect3 from "/src/assets/images/slider/slider-service-offer/whiteboard.png";
import imgLearning1 from "/src/assets/images/slider/slider-service-offer/lernumgebung.png";
import imgLearning2 from "/src/assets/images/slider/slider-service-offer/lernumgebung-vr.png";
import imgLearning3 from "/src/assets/images/slider/slider-service-offer/autorentool.png";

import "/src/components/lit-element/link-container/link-container"

import "/src/components/lit-element/news-cards/news-cards"
import SweetAlerts from "../mixins/sweet-alerts/SweetAlerts";
import {SingleButtonStrings} from "@/components/lit-element/button/single-button-strings";
import ImpressionRow from "@/components/vue/impression-row.vue/impression-row";
import learningImg from "@/assets/images/solutions/learning/screenshot-modeling-tablet.png";
import connectImg from "@/assets/images/solutions/connect/screenshots/4connect_tablet-edited.jpg";

//video
import vid4Service from "@/assets/videos/4Service.mp4"
import posterImgService from "/src/assets/videos/posters/posterServiceVideo.png";
import videoView from "@/components/vue/video-view/video-view";
import SliderComponent from "@/components/vue/slider-component/slider-component";


export default {
  name: "HomePage.vue",
  mixins: [SweetAlerts],
  components:{SliderComponent, ImpressionRow, landingHeader2, videoView},
  setup(){
    const carouselSlides = ['one', 'two', 'three', 'four'];
    return { carouselSlides };
  },
  data(){
    return{
      landingHeaderSrcArray: {
        landingHeader: LandingHeaderStrings.sliderHeaderText1(),
        landingDescriptionOne: LandingHeaderStrings.sliderText1(),
        landingDescriptionTwo: LandingHeaderStrings.sliderText2(),
        landingLinkOne: LandingHeaderStrings.sliderLink1(),
        landingLinkTwo: LandingHeaderStrings.sliderLink2(),
        landingLinkThree: LandingHeaderStrings.sliderLink3(),
        landingLinkFour: LandingHeaderStrings.sliderLink4(),
        landingLinkFive: LandingHeaderStrings.sliderLink5(),
        landingLinkSix: LandingHeaderStrings.sliderLink6(),
        landingImg: LandingImage,
        btnText: SingleButtonStrings.btnText3(),
      },
      videoSrcArray:
          {
            videoSrc: vid4Service,
            imgPoster: posterImgService,
          },
      hoverCardData: [
        {
          imgSrc: imgService1,
          imgAlt: "This is an image",
          headerText: "Ticketsystem",
          descriptionText: "Projekte effizient in Kollaboration zwischen Kunden und Dienstleister durchführen.",
          onClickRoute: "/solutions/4service",
          label: "4Service",
        },
        {
          imgSrc: imgService2,
          imgAlt: "This is an image",
          headerText: "Aufgabenmanagement",
          descriptionText: "Planen, Verwalten & Umsetzen von Aufgabenstellungen.",
          onClickRoute: "/solutions/4service",
          label: "4Service",
        },
        {
          imgSrc: imgService3,
          imgAlt: "This is an image",
          headerText: "Einsatzplanung",
          descriptionText: "Planungsqualität & bedarfsoptimierter Personaleinsatz",
          onClickRoute: "/solutions/4service",
          label: "4Service",
        },
        {
          imgSrc:imgConnect1,
          imgAlt: "This is an image showing the 4Connect solutions logo",
          headerText: "AR-Videokonferenzen",
          descriptionText: "HD Videokonferenzen mit AR-Unterstützung",
          onClickRoute: "/solutions/4connect",
          label: "4Connect",
        },
        {
          imgSrc:imgConnect2,
          imgAlt: "This is an image showing the 4Connect solutions logo",
          headerText: "Remote support",
          descriptionText: "Effizienter Service & schnelle Bereitstellung von Expertenwissen, dank kompatibler Datenbrillen.",
          onClickRoute: "/solutions/4connect",
          label: "4Connect",
        },
        {
          imgSrc:imgConnect3,
          imgAlt: "This is an image showing the 4Connect solutions logo",
          headerText: "Whiteboard",
          descriptionText: "Ideen visualisieren und auf großen Baukasten an Annotations-Tools zurückgreifen.",
          onClickRoute: "/solutions/4connect",
          label: "4Connect",
        },
        {
          imgSrc:imgLearning1,
          imgAlt: "This is an image showing the 4learning solutions logo",
          headerText: "Interaktives Schulungstool",
          descriptionText: "Trainingsmodule zur Weiterbildung von Kollegen und Kunden mit allen notwendigen Funktionen.",
          onClickRoute: "/solutions/4learning",
          label: "4Learning",
        },
        {
          imgSrc:imgLearning2,
          imgAlt: "This is an image showing the 4learning solutions logo",
          headerText: "VR-Lernumgebung",
          descriptionText: "Lerninhalte in einer effizienten & spielerischen Weise erschließen.",
          onClickRoute: "/solutions/4learning",
          label: "4Learning",
        },
        {
          imgSrc:imgLearning3,
          imgAlt: "This is an image showing the 4learning solutions logo",
          headerText: "Autorentool",
          descriptionText: "Intuitive Erstellung von E-Learning-Inhalten & Kursen.",
          onClickRoute: "/solutions/4learning",
          label: "4Learning",
        },
      ],
      sliderContent:[
        {
          quoteText:'Ein einfacher und gleichzeitig transparenter Serviceprozess ist wichtig für uns und unsere Kunden. Mit 4Service können wir Ticket- und Aufgabenfortschritte' +
              ' leicht und dennoch detailliert dokumentieren und unseren Kunden & Partnern selektiv zur Verfügung stellen.',
          quoteAuthor: "Ralf Eisenhauer",
          quotePosition: "Leiter Abwicklung Umbau & Service",
          quoteCompany: "Gehring Technologies",
          isSelected:true
        },
        {
          quoteText:'Effiziente Einsatzplanung und ein anschließendes digitales Berichtwesen ersparen uns administrative Arbeit und den Einsatz von Papier. Intern wie extern wird dieser' +
              ' Vorteil sehr geschätzt.',
          quoteAuthor: "Thomas Preyer",
          quotePosition: "Serviceleiter",
          quoteCompany: "Firma Nagel",
          isSelected:false
        },
      ],
  contentDescription: TwoButtonStrings.contentDescription(),
  buttonLabelOne: TwoButtonStrings.buttonLabelOne(),
  buttonLabelTwo: TwoButtonStrings.buttonLabelTwo(),
  buttonLabelMore: TwoButtonStrings.buttonLabelMore(),
    impressionRowContentArr: [
      {
        img: iconOne,
        app: "4Service",
        headline: "Bieten Sie Ihren Kunden optimalen Support",
        text: "Unterstützen Sie Ihre Mitarbeiter, Administratoren und internen Teams mit leistungsfähigen, sofort einsatzbereiten Kundenservice-Tools.",
        link: "Weitere Infos",
        route: "/solutions/4service",
        padding: true,
      },
      {
        img: connectImg,
        app: "4Connect",
        headline: "HD Videokonferenzen mit Augmented Reality Unterstützung",
        text: "Leiten Sie Ihre Kunden, Mitarbeiter & Kollegen an: In Echtzeit und mit einem HD-Videokonferenz System, das keine Wünsche hinsichtlich Interaktionsmöglichkeiten auslässt.",
        link: "Weitere Infos",
        route: "/solutions/4connect",
        padding: true,
      },
      {
        img: learningImg,
        app: "4Learning",
        headline: "Schulungstool zur Weiterbildung von Kollegen und Kunden",
        text: "Erstellen Sie unterschiedlichste Trainingsmodule mit allen notwendigen Funktionen und stellen so Kunden ein digitales Schulungsprogramm zusammen.",
        link: "Weitere Infos",
        route: "/solutions/4learning",
        padding: false,
      },
    ],
  customerReviewsData: [
    {
      imgSrc: logoKlausUnion,
      imgAlt: "Logo Klaus Union",
    },
    {
      imgSrc: logoGehring,
      imgAlt: "Logo der Gehring Technologies GmbH",
    },
    {
      imgSrc: logoAcm,
      imgAlt: "Logo der Aircraft Cabin Modification GmbH",
    },
    {
      imgSrc: logoGleason,
      imgAlt: "Logo der WFL MILLTURN TECHNOLOGIES GMBH & CO.KG",
    },
    {
      imgSrc: logoMae,
      imgAlt: "Logo der Maschinen und Apparatebau Götzen GmbH",
    },
    {
      imgSrc: logoNagel,
      imgAlt: "Logo der NAGEL Maschinen- & Werkzeugfabrik GmbH",
    },
  ],
}
},
methods:{
  goToSolutions(){
  this.$router.push({
    path: "/solutions/4service/ticketsystem",
  });
  },
  goToContact(){
  this.$router.push({
    path: "/about-us/contact",
  })
  },
  goToService(){
    this.$router.push({
      path: "/solutions/4service",
    });
  },
  goToLearning(){
    this.$router.push({
      path: "/solutions/4learning",
    });
  },
  goToConnect(){
    this.$router.push({
      path: "/solutions/4connect",
    });
  },
  openPopup(){
    this.$router.push({
      path: "/offer-configurator",
    });
  },

hoverCardClicked(event){
  const route = event.detail.route;

  this.$router.push({
    "path": route
  })
},
}
}
</script>

<style scoped>
.marginTop{
  margin-top: 120px;
}
.videoViewContainer{
  background-color:  var(--primary-color);
  padding: 80px;
  margin-top: 40px
}
.videoViewWrapper{
  max-width: 1100px;
  margin: auto;
}
.hoverCardsContainer{
  padding:80px;
  background-color: var(--lightgrey);
}
.impressionRowContainer{
  padding:80px;
}
.sliderComponentContainer{
  padding: 80px;
  background-color: var(--secondary-color);
}
.twoButtonContainer{
  padding: 80px;
  background-color: var(--lightgrey);
}
.headlineHome{
  margin-bottom: 80px;
}
img{
  min-width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-container div{
  position: absolute;
  display: flex;
}
.img-container span:nth-child(even){
  margin-left: 5px;
  font-size: 12px;
  max-width: 100px;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@media only screen and (max-width: 1100px) {
  .marginTop{
    margin-top: 0;
  }

  .videoViewContainer,
  .impressionRowContainer,
  .sliderComponentContainer,
  .hoverCardsContainer,
  .twoButtonContainer
  {
    padding: 80px 30px;
  }
}

@media only screen and (max-width: 720px) {

  .img-container span:nth-child(even) {
    display: none;
  }

  .img-container div:nth-child(3){
    bottom: 40px!important;
    left: 120px!important;
  }

  .img-container div:nth-child(4){
    top: 40px!important;
    left: 120px!important;
  }
  .img-container div:nth-child(5){
    right: 70px!important;
    bottom: 40px!important;
  }
}

</style>

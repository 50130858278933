import {html} from "lit";
import {LitElement} from "lit";
import {RowElementsStyles} from "./row-elements-styles";

export class RowElements extends LitElement{
    constructor() {
        super();

        this.srcArray = [];
        this.isOpen = false;
        this.popupContent = [];
    }

    static get properties(){
        return {
            srcArray: {Type: Array},
            isOpen: {Type: Boolean},
            popupContent: {Type: Array},
            shifted: {}
        }
    }

    static get styles(){
        return RowElementsStyles
    }

    showPopup(item){
        this.isOpen = true;
        this.showBg = true;
        this.popupContent = item.popupContent;
    }

    closePopup(){
        this.isOpen = false;
        this.showBg = false;
    }

    render() {
        return html`
            <div class="outerDiv"">
                <div class="contentWrapper">
                    <div class="content">
                        ${this.srcArray.map((entry)=>html`
                            <div class="innerWrapper">
                                <div class="imgWrapper">
                                    <img style="" class="elementImg" src="${entry.imgSrc}" alt="">
                                </div>
                                <div class="headerWrapper">
                                    <div class="elementHeader">${entry.headerValue}</div>
                                </div>
                                <div class="textWrapper">
                                    <div class="elementText">
                                        <span>${entry.textValue}</span>
                                        ${entry.popupContent ? html`
                                            <a class="showMore" @click="${() => this.showPopup(entry)}">mehr</a>
                                        ` : null}
                                    </div>
                                </div>
                            </div>
                            ${this.isOpen ? html`<team-member-popup @close-button-clicked="${()=>this.closePopup()}" .srcArray = ${this.popupContent}></team-member-popup>` : null}
                        `)}
                    </div>
                </div>
            </div>
            <div class="background ${this.showBg ? 'showBg' : ''}" @click="${()=>this.closePopup()}"></div>
        `
    }
}

customElements.define("row-elements", RowElements);

import {css} from "lit"

export const RowElementsStyles = css`

  .background{
    position: fixed;
    background-color: black;
    width: 0;
    height: 0;
    left: 0;
    top: 50px;
    opacity: 0;
  }

  .showBg {
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .content{
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  
  .innerWrapper{
    width: 300px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-height: 350px;
    margin: 20px;
    position: relative;
  }
  
  .headerWrapper{
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .overHeader{
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .imgWrapper{
    width: 300px;
    height: 200px;
    margin: 0 auto;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
    border-radius: 2px;
  }
  
  .show-more-btn-container{
    display: flex;
    justify-content: center;
  }
  
  .showMore{
    color: var(--primary-color);
    position: absolute;
    bottom: -30px;
    left: 50%;
    font-size: 12px;
    transform: translate(-50%);
  }
  
  .showMore:hover{
    cursor: pointer;
    text-decoration-line: underline;
  }

  .imgWrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
  }

  @media only screen and (max-width: 1050px) {
    
    .innerWrapper{
      width: auto;
      margin: auto;
    }
    
    .content{
      display: block;
    }
    
    .imgWrapper{
      margin-top: 20px;
    }

    .showMore{
      position: revert;
      transform: translate(0);
      display: block;
    }
  }

  @media only screen and (max-width: 720px) {
  

  }
  

`
import {createRouter, createWebHistory} from "vue-router";
import Home from "/src/views/Home";

const routes = [
    {
        path: '/:pathMatch(.*)',
        component: Home
    },
    {
        path: "/",
        component: Home
    },
    {
        path: "",
        component: Home
    },
    {
      path: "/home",
      component: Home
    },
    {
        path: "/about-us",
        component: () => import(/* webpackChunkName: "about" */ "/src/views/AboutUs")
    },
    {
        path: "/about-us/contact",
        component: () => import(/* webpackChunkName: "contact" */ "/src/views/Contact")
    },
    {
        path: "/about-us/partner",
        component: () => import(/* webpackChunkName: "partner" */ "/src/views/Partner")
    },
    {
        path: "/about-us/career",
        component: () => import(/* webpackChunkName: "career" */ "/src/views/Career")
    },
    {
        path: "/offer-configurator",
        component: () => import(/* webpackChunkName: "offer-configurator" */ "/src/views/offer-configurator")
    },
    {
        path: "/implementation",
        component: () => import(/* webpackChunkName: "implementation" */ "/src/views/Implementation")
    },
    {
        path: "/solutions/4service",
        component: () => import(/* webpackChunkName: "4service" */ "/src/views/solutions/4service")
    },
    {
        path: "/solutions/4connect",
        component: () => import(/* webpackChunkName: "4connect" */ "/src/views/solutions/4connect")
    },
    {
        path: "/solutions/4learning",
        component: () => import(/* webpackChunkName: "4learning" */ "/src/views/solutions/4learning")
    },
    {
        path: "/news",
        component: () => import(/* webpackChunkName: "news" */"/src/views/News")
    },
    {
        path: "/data-privacy",
        component: () => import(/* webpackChunkName: "data-privacy" */"/src/views/small/DataPrivacy")
    },
    {
        path: "/legal-notice",
        component: () => import(/* webpackChunkName: "legal-notice" */"/src/views/small/LegalNotice")
    },
    {
        path: "/solutions/4service/planning-board",
        component: () => import(/* webpackChunkName: "planning-board" */ "/src/views/functions/planning-board")
    },
    {
        path: "/solutions/4service/ticketsystem",
        component: () => import(/* webpackChunkName: "ticketsystem" */ "/src/views/functions/ticketsystem")
    },
    {
        path: "/solutions/4service/reporting",
        component: () => import(/* webpackChunkName: "ticketsystem" */ "/src/views/functions/reporting")
    },
]

//When using createWebHistory(), the URL will
// look "normal," https://example.com/user/id (without hashtag)
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        // return desired position
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    }
})

router.beforeEach(() => {
    //window.scrollTo(0, 0)
})

export default router

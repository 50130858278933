import {html} from "lit";
import {LitElement} from "lit";
import {PrimaryTextStyles} from "./primary-text-styles";


export class PrimaryText extends LitElement{

    constructor() {
        super();
        this.primaryTextHeader = "";
        this.primaryTextDescription = "";
    }

    static get properties(){
        return {
            primaryTextHeader:{Type:String},
            primaryTextDescription:{Type:String},
        }
    }

    static get styles(){
        return PrimaryTextStyles;
    }


    render(){
        return html`
            <div class="outerDiv">
                    <div class="contentWrapper">
                        <h2 class="itemHeader">${this.primaryTextHeader}</h2>
                        <div class="itemText">${this.primaryTextDescription}</div>
                    </div>
            </div>
        `
    }
}


customElements.define("primary-text", PrimaryText);
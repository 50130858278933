<template>
  <div class="container">
    <div class="mobile-header" :class="{boxShadow: scrollPosition > 100}">
      <div @click="this.navigate('/home')">
        <img :src="codevanceLogo" alt="" style="width: 100%;max-width: 190px;display: flex;">
      </div>
      <div @click="this.isOpen = !this.isOpen" class="menu-button">
        <span v-if="!isOpen" >
          <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#30343F"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>
        </span>
        <span v-if="isOpen">
          <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 0 24 24" width="40px" fill="#30343F"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/></svg>
        </span>
      </div>
      <transition name="nested" :duration="550">
        <div v-if="isOpen" class="nav-content-container outer">
          <div class="inner flex">
            <span class="main-tab" @click="this.navigate('/')">{{home}}</span>
            <span class="main-tab">{{solutions}}</span>
            <div class="sub-nav">
              <!--<span @click="this.navigate('/about-us/team')">{{ this.team }}</span>-->
              <span @click="this.navigate('/solutions/4service')">{{ this.service }}</span>
              <span @click="this.navigate('/solutions/4connect')">{{ this.connect }}</span>
              <span @click="this.navigate('/solutions/4learning')">{{this.learning}}</span>
            </div>
            <span class="main-tab">{{functions}}</span>
            <div class="sub-nav">
              <!--<span @click="this.navigate('/about-us/team')">{{ this.team }}</span>-->
              <span @click="this.navigate('/solutions/4service/ticketsystem')">{{ this.ticketsystem }}</span>
              <span @click="this.navigate('/solutions/4connect/planning-board')">{{ this.planningboard }}</span>
              <span @click="this.navigate('/solutions/4learning/reporting')">{{this.reporting}}</span>
            </div>
            <span @click="this.navigate('/about-us')" class="main-tab" style="border-top: 1px solid var(--lightgrey)">{{ aboutUs }}</span>
            <div class="sub-nav">
              <span @click="this.navigate('/about-us')">{{this.company}}</span>
              <span @click="this.navigate('/about-us/career')">{{this.career}}</span>
              <span @click="this.navigate('/about-us/partner')">{{ this.partner }}</span>
              <span @click="this.navigate('/news')">{{ this.news }}</span>
            </div>
            <span class="main-tab" @click="this.navigate('/implementation')">{{ implementation }}</span>
            <span class="main-tab" @click="this.navigate('/about-us/contact')">{{ contact }}</span>
          </div>
          <footer-bar></footer-bar>
        </div>
      </transition>

    </div>
  </div>

</template>

<script>
import {NavigationBarLocales} from "@/locales/navigation-bar/navigation-bar-locales";
import FooterBar from "../footer-bar/FooterBar";

// images
import codevanceLogo from "/src/assets/logos/codevance_logo_positiv.svg";

export default {
  name: "MobileNavBar",
  components:{FooterBar},
  data(){
    return{
      isOpen: false,
      scrollPosition: null,
      codevanceLogo: codevanceLogo,
      home: NavigationBarLocales.home(),
      aboutUs: NavigationBarLocales.aboutUs(),
      contact: NavigationBarLocales.contact(),
      implementation: NavigationBarLocales.implementation(),
      partner:NavigationBarLocales.partner(),
      career: NavigationBarLocales.career(),
      //team: NavigationBarLocales.team(),
      solutions: NavigationBarLocales.solutions(),
      functions: NavigationBarLocales.functions(),
      service: NavigationBarLocales.service(),
      connect: NavigationBarLocales.connect(),
      learning: NavigationBarLocales.learning(),
      ticketsystem: NavigationBarLocales.ticketsystem(),
      planningboard: NavigationBarLocales.planningboard(),
      reporting: NavigationBarLocales.reporting(),
      news: NavigationBarLocales.news(),
      pricing: NavigationBarLocales.pricing(),
      company: NavigationBarLocales.company(),
    }
  },
  methods:{
    // navigate to a view, by providing the path.
    // each path needs to be registered inside the router
    // component first.
    navigate(extension){
      this.$router.push({
        path: extension,
      })
      this.isOpen = false;
    },
    //for adding border-shadow to header when user starts scrolling page
    updateScroll() {
      this.scrollPosition = window.scrollY
    }
  },

  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  }
}

</script>

<style scoped>

.flex{
  display: flex;
  flex-direction: column;
}

.boxShadow{
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
  background-color: white;
}

.sub-nav{
  padding: 20px 30px;
}

.nav-content-container .sub-nav span{
  padding: 20px;
  font-size: 16px;
}

.nav-content-container .main-tab{
  padding: 20px 30px;
  font-size: 18px;
  border-bottom: 1px solid var(--lightgrey);
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  opacity: 0;
}

/* delay enter of nested element for staggered effect */
.nested-enter-active .inner {
  transition-delay: 0.25s;
}
  .mobile-header{
    height: 50px;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 2;
  }

  body{
    overflow: hidden;
  }

  .nav-content-container{
    position: absolute;
    left: 0;
    top: 50px;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    text-align: left;
    overflow: scroll;
  }

  .menu-button span{
    display: flex;
  }

@media only screen and (max-width: 480px) {
  .sub-nav{
    display: flex;
    flex-direction: column;
    padding: 0 0 0 20px;
  }
}


</style>

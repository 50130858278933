<template>

  <div class="outerContainer" :class="{boxShadow: scrollPosition > 100}">
    <div class="outerWrapper">
      <div @click="this.navigate('/home')" class="imgContainer">
        <img :src="codevanceLogo" alt="">
      </div>
      <div class="tabNavigation">
        <div @mouseleave="this.triggerSubNavigation(false, 'solutions')"
             @mouseenter="this.triggerSubNavigation(true, 'solutions')"
             class="tabContainer">
          <span>{{ solutions }}</span>
          <div v-if="this.showSolutionsSubNav" class="dropContainer">
            <ul>
              <h3>Nach Produkt</h3>
              <li @click="this.navigate('/solutions/4service')">
                <a class="mainHeadline">{{this.service}}</a>
              </li>
              <li @click="this.navigate('/solutions/4connect')">
                <a class="mainHeadline">{{ this.connect }}</a>
              </li>
              <li @click="this.navigate('/solutions/4learning')">
                <a class="mainHeadline">{{this.learning}}</a>
              </li>
            </ul>
            <ul>
              <h3>Nach Funktion</h3>
              <li @click="this.navigate('/solutions/4service/ticketsystem')">
                <a class="mainHeadline">Ticketsystem</a>
              </li>
              <li @click="this.navigate('/solutions/4service/reporting')">
                <a class="mainHeadline">Berichtswesen</a>
              </li>
              <li @click="this.navigate('/solutions/4service/planning-board')">
                <a class="mainHeadline">Plantafel</a>
              </li>
              <li @click="this.navigate('/solutions/4connect')">
                <a class="mainHeadline">AR-Videokonferenzen</a>
              </li>
              <li @click="this.navigate('/solutions/4learning')">
                <a class="mainHeadline">E-Learning</a>
              </li>
            </ul>
          </div>
        </div>

        <div @mouseleave="this.triggerSubNavigation(false, 'aboutUs')"
             @mouseenter="this.triggerSubNavigation(true, 'aboutUs')"
             class="tabContainer">
          <span>{{ aboutUs }}</span>
          <div v-if="this.showAboutUsSubNav" class="dropContainer">
            <ul>
              <li @click="this.navigate('/about-us')">
                <a class="mainHeadline">Unternehmen</a>
              </li>
              <li @click="this.navigate('/about-us/career')">
                <a class="mainHeadline">{{ this.career }}</a>
              </li>
              <li @click="this.navigate('/about-us/partner')">
                <a class="mainHeadline">{{ this.partner }}</a>
              </li>
              <li @click="this.navigate('/news')">
                <a class="mainHeadline">{{ news }}</a>
              </li>
              <li @click="this.navigate('/about-us/contact')">
                <a class="mainHeadline">{{ contact }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div @click="this.navigate('/implementation')" class="tabContainer">
          <a>{{ implementation }}</a>
        </div>
      </div>
    </div>

    <div class="actionWrapper">
      <a href="https://app.4service.io/" class="loginContainer"  target="_blank">
        <span>Login</span>
      </a>
      <div>
        <single-button @button-clicked="this.navigate('/offer-configurator')" .btnText="btnText"></single-button>
      </div>
    </div>
  </div>
</template>

<script>

//components
import "/src/components/lit-element/button/single-button"

// images
import codevanceLogo from "/src/assets/logos/codevance_logo_positiv.svg";

// strings
import {NavigationBarLocales} from "/src/locales/navigation-bar/navigation-bar-locales";
import {SingleButtonStrings} from "@/components/lit-element/button/single-button-strings";

export default {
  name: "navigation-bar",
  components: "",
  data(){
    return{
      codevanceLogo: codevanceLogo,
      btnText: SingleButtonStrings.btnText2(),
      home: NavigationBarLocales.home(),
      aboutUs: NavigationBarLocales.aboutUs(),
      partner:NavigationBarLocales.partner(),
      career: NavigationBarLocales.career(),
      team: NavigationBarLocales.team(),
      implementation: NavigationBarLocales.implementation(),
      solutions: NavigationBarLocales.solutions(),
      service: NavigationBarLocales.service(),
      connect: NavigationBarLocales.connect(),
      learning: NavigationBarLocales.learning(),
      news: NavigationBarLocales.news(),
      pricing: NavigationBarLocales.pricing(),
      contact: NavigationBarLocales.contact(),

      scrollPosition: null,

      showAboutUsSubNav: false,
      showSolutionsSubNav: false,
    }
  },

  methods:{
    // navigate to a view, by providing the path.
    // each path needs to be registered inside the router
    // component first.
    navigate(extension){
      this.$router.push({
        path: extension,
      })
    },

    triggerSubNavigation(shouldShow, navigationKey){
      switch (navigationKey.toLowerCase()){
        case "aboutus":
          this.showAboutUsSubNav = !this.showAboutUsSubNav;
          break;
        case "solutions":
          this.showSolutionsSubNav = !this.showSolutionsSubNav;
          break;
      }
    },

    //for adding border-shadow to header when user starts scrolling page
    updateScroll() {
      this.scrollPosition = window.scrollY
    }
  },

  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  }
}

</script>

<style scoped>
.outerContainer{
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
}

.boxShadow{
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
  background-color: white;
}

.outerWrapper{
  display: flex;
  align-items: center;
}

.tabNavigation{
  display: flex;
  align-items: center;
}

a{
  text-decoration: none;
}

.imgContainer{
  max-width: 150px;
  margin: 7px 14px 0;
  cursor: pointer;
}

.imgContainer img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.actionWrapper{
  display: flex;
  align-items: center;
}

.actionWrapper > *{
  margin: 7px 14px 0;
  padding-bottom: 5px;
}

.loginContainer{
  display: flex;
  align-items: center;
  cursor: pointer;
  color: black;
}

.loginContainer:hover span{
  color: var(--primary-color) !important;
}

h3{
  margin: 0 0 20px 0;
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-grey);
  white-space: nowrap;
}

.mainHeadline{
  font-weight: normal;
}

a{
  border-bottom: 2px solid transparent;
  transition: border-color 0.2s ease-in-out;
  color: var(--dark-grey);
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}

a:hover{
  border-color: var(--primary-color);
}

.tabContainer{
  position: relative;
  margin: 7px 14px 0;
  padding-bottom: 5px;
  cursor: pointer;
}

.tabContainer > span{
  border-bottom: 2px solid transparent;
  transition: border-color 0.2s ease-in-out;
}

.tabContainer:hover > span{
  border-color: var(--primary-color);
}

.dropContainer{
  position: absolute;
  top: 24px;
  left: 0;
  color: black;
  display: flex;
  text-align: left;
  gap: 40px;
  background-color: white;
  padding: 40px 60px 60px 40px;
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
}

.dropContainer ul li{
  margin-bottom: 20px;
  white-space: nowrap;
}

.dropContainer ul li:last-child{
  margin-bottom: 0;
}





</style>

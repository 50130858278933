<template>
  <div class="outerDiv">
    <div class="innerDiv">
      <h1 style="position: absolute; top: 0; right: 0; opacity: 0;">Codevance software development</h1>
      <div class="contentWrapper">
        <strong>{{srcArray.app}}</strong>
        <h2 class="headline">{{srcArray.landingHeader}}</h2>
        <h3 class="description">{{srcArray.landingDescriptionOne}}</h3>
        <ul class="checkListContainer">
          <li v-if="srcArray.landingLinkOne">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="limegreen" data-v-5a937634="" data-v-6131bae4=""><path d="M0 0h24v24H0z" fill="none" data-v-5a937634="" data-v-6131bae4=""></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" data-v-5a937634="" data-v-6131bae4=""></path></svg>
            </span>
            <span>{{ srcArray.landingLinkOne }}</span>
          </li>
          <li v-if="srcArray.landingLinkTwo">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="limegreen" data-v-5a937634="" data-v-6131bae4=""><path d="M0 0h24v24H0z" fill="none" data-v-5a937634="" data-v-6131bae4=""></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" data-v-5a937634="" data-v-6131bae4=""></path></svg>
            </span>
            <span>{{ srcArray.landingLinkTwo }}</span>
          </li>
          <li v-if="srcArray.landingLinkThree">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="limegreen" data-v-5a937634="" data-v-6131bae4=""><path d="M0 0h24v24H0z" fill="none" data-v-5a937634="" data-v-6131bae4=""></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" data-v-5a937634="" data-v-6131bae4=""></path></svg>
            </span>
            <span>{{ srcArray.landingLinkThree }}</span>
          </li>
          <li v-if="srcArray.landingLinkFour">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="limegreen" data-v-5a937634="" data-v-6131bae4=""><path d="M0 0h24v24H0z" fill="none" data-v-5a937634="" data-v-6131bae4=""></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" data-v-5a937634="" data-v-6131bae4=""></path></svg>
            </span>
            <span>{{ srcArray.landingLinkFour }}</span>
          </li>

          <li v-if="srcArray.landingLinkFive">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="limegreen" data-v-5a937634="" data-v-6131bae4=""><path d="M0 0h24v24H0z" fill="none" data-v-5a937634="" data-v-6131bae4=""></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" data-v-5a937634="" data-v-6131bae4=""></path></svg>
            </span>
            <span>{{ srcArray.landingLinkFive }}</span>
          </li>
          <li v-if="srcArray.landingLinkSix">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="limegreen" data-v-5a937634="" data-v-6131bae4=""><path d="M0 0h24v24H0z" fill="none" data-v-5a937634="" data-v-6131bae4=""></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" data-v-5a937634="" data-v-6131bae4=""></path></svg>
            </span>
            <span>{{ srcArray.landingLinkSix }}</span>
          </li>
        </ul>
        <div class="btnContainer">
          <button class="mainBtn" @click="goToConfigurator">Jetzt starten</button>
        </div>
      </div>
      <div class="imgContainer">
        <img style="height: 100%; width: 100%; object-fit: contain;" :src="this.srcArray.landingImg" alt="">
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "landing-header-2",
  components: {},
  props: ['srcArray'],
  methods:{
    goToService(){
      this.$router.push({
        path: "/solutions/4service",
      });
    },

    goToConnect(){
      this.$router.push({
        path: "/solutions/4connect",
      });
    },

    goToLearning(){
      this.$router.push({
        path: "/solutions/4learning",
      });
    },
    goToConfigurator(){
      this.$router.push({
        path: "/offer-configurator",
      });
    },
  }
}
</script>

<style scoped>

@media only screen and (min-width: 1600px){
  .innerDiv{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.outerDiv {
  height: calc(100vh - 80px);
}

.innerDiv{
  display: flex;
  width: 1100px;
  margin: auto;
}

.contentWrapper{
  width: 100%;
  text-align: left;
  margin-right: 80px;
}

strong{
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}

.imgContainer{
  width: 100%;
}

.headline{
  color: var(--dark-grey);
  text-align: left;
  width: 100%;
  max-width: 600px;
  margin: 0 0 20px;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.31;
}

.description{
  text-align: left;
  color: var(--grey);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.45;
  margin: 30px 0 30px 0;
}

.checkListContainer{
  column-count: 2;
}

li {
  padding: 0;
  list-style: none;
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--dark-grey);
  font-weight: 500;
  line-height: 1.45;
  position: relative;
  z-index: 1;
}

li span{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

img{
  filter:drop-shadow(30px 10px 4px var(--grey));
}

.btnContainer{
  margin-top: 30px;
}

.mainBtn {
  height: 40px;
  width: 225px;
  background-color: var(--primary-color);
  color: white;
  border: 2px solid var(--primary-color);
  border-radius: 2px;
  font-size: 16px;
  font-weight: 600;
}

.mainBtn:hover {
  border: 2px solid #A6A6A6;
  cursor: pointer;
}



@media only screen and (max-width: 1100px) {
  .innerDiv{
    display: block;
    position: unset;
    transform: unset;
    width: 100%;
    max-width: 1100px;
    margin: auto;
  }
  .outerDiv {
    height: auto;
    padding: 80px 30px 80px 30px;
  }
  .headline{
    font-size: 2rem;
  }
  .imgContainer{
    margin-top: 40px;
  }

  .headline{
    max-width: none;
  }

  .checkListContainer{
    margin-bottom: 30px;
  }

  .btnContainer{
    margin-top: 0;
  }
}

@media only screen and (max-width: 600px) {
  .checkListContainer{
    column-count: 1;
  }
  .mainBtn{
    width: 100%;
  }

  .outerDiv{
    padding-top: 120px;
  }
}
</style>
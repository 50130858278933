import {css} from "lit";

export const TextIconStyles = css`
  
  .contentWrapper{
    display: flex;
    justify-content: space-around;
    max-width: 1100px;
    height: fit-content;
    flex-wrap: wrap;
    margin: auto;
  }

  .rowOne{
    width: 80%;
    display: flex;
    justify-content: space-around;
  }

  .itemOuterDiv{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

  }

  .itemHeader{
    font-weight: 500;
  }
  
  .itemImageDiv{
    padding-right: 5px;
    margin-right: 5px;
    border-right: 2px solid var(--primary-color);
    height: 100%;
    display: flex;
    align-items: center;
  }

  .itemImage{
    height: 24px;
    width: 24px;
  }

  .itemTextDiv{
    width: fit-content;
    text-align: left;
  }

  @media only screen and (max-width: 1050px) {
    .contentWrapper{
      display: grid;
      grid-template-columns: auto auto;
    }
    
    .itemOuterDiv{
      margin-right: 15px;
    }
    
    .itemOuterDiv:last-child{
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 720px) {
    .outerDiv{
      padding: 0 20px;
    }
  }

  @media only screen and (max-width: 480px) {
    .contentWrapper{
      grid-template-columns: auto;
    }
  }
    
`
import {css} from "lit";

export const NewsCardsStyles = css`

  .outerDiv {
    max-width: 1110px;
    margin: 100px auto auto auto;
  }

  .content {
    display: flex;
    justify-content: center;
    height: fit-content;
  }

  .contentWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .img {
    width: 100%;
  }

  .mainCardContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: fit-content;
    min-height: 750px;
    align-items: center;
  }

  .mainCard {
    width: 80%;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all .2s ease-in-out;
    min-height: 750px;
    margin-bottom: 50px;
    text-align: left;
  }

  .mainCard:hover{
    transform: scale(1.1);
    cursor: pointer;
  }

  .smallCardContainer {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 750px;
    align-items: center;
  }

  .smallCard {
    width: 80%;
    max-height: 30%;
    height: 30%;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    transition: all .2s ease-in-out;
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: left;
  }

  .smallCard:hover{
    transform: scale(1.1);
    cursor: pointer;
  }

  .headerWrapper {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    font-weight: 500;
    color:black;
  }
  
  .dateWrapper {
    padding: 10px 20px;
    font-size: smaller;
    color: #9b9b9b;
  }

  .textWrapper {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .text{
    color:black;
    font-weight:normal;
  }

  .showMore{
    display: flex;
    justify-content: end;
    margin-right: 20px;
    padding-left: 20px;
    padding-top: 5px;
    font-size: 12px;
  }

  .showMoreContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 1050px) {
    .content{
      width: 100%;
      flex-wrap: wrap;
    }
    
    .mainCardContainer{
      width: 100%;
      padding: 20px;
    }
    
    .mainCard{
      width: 100%;
      margin-bottom: 0;
    }
    
    .smallCardContainer{
      width: 100%;
      display: block;
      padding: 20px;
    }
    
    .smallCard{
      width: 100%;
    }
    
    
  }



`
import {css} from "lit";

export const TeamMemberPopupStyles = css`
  
  .contentWrapper{
    width: 800px;
    background-color: white;
    z-index: 2;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
    border-radius: 2px;
  }
  
  .memberName{
    margin-right: 12px;
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .memberJob{
    font-size: .875rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .memberJob svg{
    margin-right: 3px;
  }
  
  .memberDescription{
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .content{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 40px;
  }
  
  .contact a{
    color: var(--primary-color);
    text-decoration: none;
  }
  
  .contact:hover{
    text-decoration: underline;
  }
  
  .closeButton{
  cursor: pointer
  }
  
  .closeButton svg{
    fill: grey;
    width: 18px;
    height: 18px;
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .closeButton:hover svg{
    background-color: lightgray;
    fill: white;
    border-radius: 50%;
  }
  
  .buttonContainer button{
    height: 40px;
    width: 225px;
    background-color: transparent;
    color: var(--dark);
    border: 2px solid var(--primary-color);
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.2s ease 0s;
    cursor: pointer;
  }

  .buttonContainer button:hover {
    border: 2px solid rgb(166, 166, 166);
    cursor: pointer;
  }
  .memberImage{
    width:auto;
    max-height:400px;
    height:72px;
  }
  
  .contentLeft{
  }

  .contentRight{
    margin-left: 40px;
    z-index: 1;
    text-align: left;
  }

  @media only screen and (max-width: 840px) {
    .contentWrapper{
      top: 50px;
      left: 0;
      width: 100%;
      transform: none;
    }
  }

  @media only screen and (max-width: 720px) {
    .content{
      flex-direction: column-reverse;
      padding: 20px;
    }

    .memberImage{
      min-width: auto;
    }

    .contentRight{
      margin-left: 0;
    }
  }

 
  
  
`
<template>
  <div class="outerContainer">
    <div v-for="impression in srcArray" :key="impression">
      <div :class="impression.isComponent ? 'flexColumn' : 'innerContainer'" >
        <div class="contentWrapper">
          <h2 class="app">{{impression.app}}</h2>
          <h3 class="headline">{{impression.headline}}</h3>
          <p class="text">{{impression.text}}</p>
          <span class="link" @click="this.$router.push({'path':impression.route})">{{impression.link}}</span>
        </div>
        <div :class="srcArray.length > 1 && !impression.isComponent ? 'marginBottom' : ''" v-if="impression.img" class="imgContainer">
          <img :src="impression.img" alt="codevance software gmbh"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
TODO:: add 'padding' class when propertie isPadding is true and add it to impression-row / home
 */
export default {
  name: "impression-row",
  props: {
    srcArray: {
      type: Array,
      required: true,
    }
  }
}
</script>

<style scoped>

  .outerContainer{
    width: 100%;
    box-sizing: border-box;
    max-width: 1100px;
    margin: auto;
  }
/*noinspection CssUnusedSymbol*/
  .innerContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
/*noinspection CssUnusedSymbol*/
  .flexColumn{
    display: flex;
    flex-direction: column;
  }

/*noinspection CssUnusedSymbol*/
.marginBottom{
    margin-bottom: 80px;
  }

  .contentWrapper{
    width: 100%;
    text-align: left;
  }

  .app{
    font-size: 16px;
    font-weight: 500;
  }

  .headline{
    font-weight: 400;
    font-size: 25px;
  }

  .text{
    font-size: 16px;
  }

  .link{
    color: var(--primary-color);
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 2px;
    cursor: pointer;
  }

  .link:hover{
    border-bottom: 2px solid var(--primary-color);
  }

  .innerContainer .imgContainer{
    background-color: var(--lightgrey);
    width: 100%;
    margin-left: 80px;
  }

  .flexColumn .imgContainer{
    background-color: var(--lightgrey);
    width: 100%;
    margin-left: 0;
    margin-top: 40px;
  }

  .imgContainer img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 2px;
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
  }

  @media only screen and (max-width: 1100px) {

    /*noinspection CssUnusedSymbol*/
    .innerContainer{
      display: block;
    }

    .contentWrapper{
      min-width: auto;
      width: auto;
      margin-right: 40px;
    }

    .imgContainer{
      margin-left: 0!important;
      margin-top: 40px;
    }

    .imgContainer img{
      height: auto;
    }
  }

  @media only screen and (max-width: 820px) {
    .innerContainer, .flexColumn{
      flex-direction: column-reverse;
    }
    .imgContainer{
      max-width: none;
    }


  }
</style>
<template>
  <div class="outerContainer">
    <footer>
        <div class="columnWrapper">
          <div class="imgContainer">
            <img style="width: 100%" :src="cvLogo" alt="This is a facebook icon">
          </div>
          <ul class="tabList">
            <li>
              <a @click="this.goToRoute('/data-privacy')" class="text small">Datenschutz</a>
            </li>
            <li>
              <a @click="this.goToRoute('/legal-notice')" class="text small">Impressum</a>
            </li>
            <li>
              <a class="text small" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktop&hl=de">Cookie-Einstellungen</a>
            </li>
          </ul>
          <div class="socialWrapper">
            <a href="https://www.linkedin.com/company/codevance-software-development-gmbh/">
              <img style="width: 24px; height: 24px" :src="linkedInLogo" alt="This is a linkedin icon">
            </a>
            <a href="https://www.facebook.com/Codevance-GmbH-109061374254575/">
              <img style="width: 24px; height: 24px" :src="facebookLogo" alt="This is a facebook icon">
            </a>
          </div>
        </div>
        <div class="columnWrapper">
          <h5>Produkte</h5>
          <ul class="tabList">
            <li>
              <a @click="this.goToRoute('/solutions/4service')">4Service</a>
            </li>
            <li>
              <a @click="this.goToRoute('/solutions/4connect')">4Connect</a>
            </li>
            <li>
              <a @click="this.goToRoute('/solutions/4learning')">4Learning</a>
            </li>
          </ul>
        </div>

        <div class="columnWrapper">
          <h5>Funktionen</h5>
          <ul class="tabList">
            <li>
              <a @click="this.goToRoute('/solutions/4service/ticketsystem')">Ticketsystem</a>
            </li>
            <li>
              <a @click="this.goToRoute('/solutions/4service/reporting')">Berichtswesen</a>
            </li>
            <li>
              <a @click="this.goToRoute('/solutions/4service/planning-board')">Plantafel</a>
            </li>
            <li>
              <a @click="this.goToRoute('/solutions/4connect')">AR-Videokonferenzen</a>
            </li>
            <li>
              <a @click="this.goToRoute('/solutions/4learning')">E-Learning</a>
            </li>
          </ul>
        </div>

        <div class="columnWrapper">
          <h5>Über CV</h5>
          <ul class="tabList">
            <li>
              <a @click="this.goToRoute('/about-us')">Unternehmen</a>
            </li>
            <!--<li>
              <a @click="this.goToRoute('/about-us/team')">Team</a>
            </li>-->
            <li>
              <a @click="this.goToRoute('/about-us/career')">Karriere</a>
            </li>
            <li>
              <a @click="this.goToRoute('/about-us/partner')">Kunden</a>
            </li>
            <li>
              <a @click="this.goToRoute('/news')">News</a>
            </li>
          </ul>
        </div>

        <div class="columnWrapper">
          <h5>
            <a @click="this.goToRoute('/contact')">Kontakt</a>
          </h5>
          <ul class="tabList">
            <li>
              <span>Codevance Software Development GmbH</span>
            </li>
            <li>
              <span>Gartenstraße 19</span>
            </li>
            <li>
              <span>D-71272 Renningen</span>
            </li>
            <li>
              <a href="tel:+4971598059201">+49 7159 8059201</a>
            </li>
            <li>
              <a href="mailto:info@codevance.de">info@codevance.de</a>
            </li>
          </ul>
      </div>
    </footer>
<!--

    -->
  </div>

</template>

<script>

import SweetAlerts from "../../../mixins/sweet-alerts/SweetAlerts";
import {HttpsRequests} from "../../../mixins/https-requests";
import {FooterBarStrings} from "../../../locales/footer-bar/footer-bar-strings";

import facebookLogo from "/src/assets/images/social/facebook.png";
import linkedInLogo from "/src/assets/images/social/linkedin.png";
import cvLogo from "@/assets/logos/codevance_logo_positiv.svg";



export default {
  name: "FooterBar",
  mixins: [SweetAlerts],
  data(){
    return{
      email: "",
      facebookLogo: facebookLogo,
      linkedInLogo: linkedInLogo,
      cvLogo: cvLogo,
    }
  },
  methods:{

    goToRoute(route){
      this.$router.push({
        "path": route
      })
    },

    isEmailValid(email){
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    subscribeToNewsletter(){
      if(this.isEmailValid(this.email)){

        const payload = {
          email: this.email
        };

        HttpsRequests.postRequest("subscribeToNewsletter", payload).then(() => {
          this.email = "";

          this.showSuccessMessage(FooterBarStrings.successTitle(), FooterBarStrings.subscribedToNewsLetterText());
        })

        } else {
          this.showErrorMessage(FooterBarStrings.errorTitle(), FooterBarStrings.subscribedToNewsLetterErrorText());
      }
    }
  }
}
</script>

<style scoped>

.outerContainer{
  padding: 80px;
  background-color: white;
}

footer{
  display: flex;
  justify-content: space-between;
  gap: 40px;
  max-width: 1100px;
  margin: auto;
}

.columnWrapper{
  max-width: 200px;
}

.tabList{
  display: flex;
  flex-direction: column;
  text-align: left;
}

a{
  border-bottom: 2px solid transparent;
  transition: border-color 0.2s ease-in-out;
  color: var(--dark-grey);
  cursor: pointer;
  text-decoration: none;
}

a:hover{
  border-color: var(--primary-color);
}

h5{
  text-align: left;
  margin: 0 0 20px 0;
  color: var(--dark-grey);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

li{
  margin-bottom: 16px;
}

li:last-child{
  margin-bottom: 0;
}

.socialWrapper{
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.imgContainer{
  margin-bottom: 20px;
}

@media only screen and (max-width: 1100px) {
  .outerContainer{
    padding: 80px 30px;
  }
}
@media only screen and (max-width: 720px) {
  footer{
    flex-direction: column;
  }
}

</style>

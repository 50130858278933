
export class NavigationBarLocales{

    static home(){
        return "Startseite";
    }

    static aboutUs(){
        return "Über uns";
    }
    static company(){
        return "Unternehmen";
    }
    static team(){
        return "Team";
    }
    static contact(){
        return "Kontakt";
    }
    static career(){
        return "Karriere";
    }
    static partner(){
        return "Kunden";
    }
    static implementation(){
        return "Implementierung";
    }
    static solutions(){
        return "Lösungen";
    }
    static functions(){
        return "Funktionen";
    }
    static service(){
        return "4Service";
    }
    static connect(){
        return "4Connect";
    }
    static learning(){
        return "4Learning";
    }
    static ticketsystem(){
        return "Ticketsystem";
    }
    static planningboard(){
        return "Plantafel";
    }
    static reporting(){
        return "Berichtswesen";
    }
    static news(){
        return "News";
    }
    static pricing(){
        return "Preise";
    }
}
import {css} from "lit";

export const QuickActionSidebarStyles = css`
    .content-wrapper{
      width: 45px;;
      background-color: var(--primary-color);
      z-index: 1;
      position: fixed;
      top: 50%;
      left: 0;
      transition: all .2s ease-in-out;
      border-radius: 0 2px 2px 0;
    }
  
  a{
    text-decoration: none;
  }
  
  .icon svg{
    fill: white;
    width: 36px;
  }
  
  .icon{
    position: relative;
    top: 2px;
  }
  
  .content-wrapper:hover{
    width: 202px;
    transition: all .2s ease-in-out;
    cursor: pointer;

  }

  .text{
    color: transparent;
    margin-left: 5px;
  }


  .content-wrapper:hover .text{
    color: white;
  }
  

  .content{
    display: flex;
    align-items: center;
    padding: 5px;
  }
  
  .content:hover .text{
    color: var(--secondary-color);;
  }
  
  .content:hover{
    background-color: lightgrey;
  }

  .content:hover svg{
    fill: var(--secondary-color);
  }
  
  hr{
    margin-block-start: 0;
    margin-block-end: 0;
   
    border-width:0;
    color:var(--secondary-color);
    background-color:white;
  }
`
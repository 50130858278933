import {css} from "lit";

export const InfoIconsStyles = css`
  
  .contentWrapper{
    margin-top: 40px;
    margin-bottom: 80px;
    border-top: var(--primary-color) 2px solid;
    width: 100%;
    padding: 5px 0;
  }

  .innerContentWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .outerDiv{
    display: flex;
    justify-content: center;
    max-width: 1100px;
    margin: auto;
  }
  
  .header{
    margin-right: 60px;
  }
  
  .content{
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .content:hover{
    cursor: pointer;
  }
  
  .img{
  }
  
  .text{
    text-decoration: none;
    color: black;
    height: fit-content;
  }
  
  .row{
    display: flex;
    justify-content: space-evenly;
  }
  
  
  .imgWrapper{
    display: flex;
    justify-content: center;
    height: 24px;
    width: 24px;
    margin-top: 5px;
    margin-right: 5px;
    align-items: center;
  }
  
  .textWrapper{
    display: flex;
    justify-content: center;
    text-align: center;
    max-height: 50%;
    margin-top: 5px;
    width: fit-content;
  }
  
  .link{
    text-decoration: none;
  }

  @media only screen and (max-width: 1050px) {
    .content{
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  @media only screen and (max-width: 480px) {
    .innerContentWrapper{
      display: grid;
      justify-content: center;
    }

    .contentWrapper{
      margin: 0;
      padding: 20px;
    }
  }
  
`
import {html} from "lit";
import {LitElement} from "lit";
import {NewsCardsStyles} from "./news-cards-styles";

//TODO:: Sweet alert einbinden
//import {SweetAlerts} from "../../mixins/sweet-alerts/sweet-alerts";

export class NewsCards extends LitElement{
    constructor(){
        super()

        this.mainCardSrcArray = [];

        this.smallCardSrcArray = [];
    }

    static get styles(){
        return NewsCardsStyles;
    }

    static get properties(){
        return {
            mainCardSrcArray: {Type: Array},
            smallCardSrcArray: {Type: Array}
        }
    }

    linkClicked(linkSrc){
        if (this.isExternalURL(linkSrc)) {
            window.open(linkSrc);
        } else {
            //show sweet alert (?)
        }
    }

    isExternalURL(linkSrc){
        return linkSrc.includes("https://") || linkSrc.includes(".")
    }

    render() {return html`
        <div class="outerDiv">
            <div class="contentWrapper">
                <div class="content">
                    <div class="mainCardContainer">
                        ${this.mainCardSrcArray.map((entry)=>html`
                            <div @click="${()=> this.linkClicked(entry.linkSrc)}" class="mainCard">
                                <div class="mainContainer">
                                    <div class="imgWrapper">
                                        <img class="img" src="${entry.imgSrc}" alt="${entry.imgAlt}">
                                    </div>
                                    <div class="headerWrapper">
                                        <div class="header">${entry.headerValue}</div>
                                    </div>
                                    <div class="dateWrapper">
                                        <div class="date">${entry.dateValue}</div>
                                    </div>
                                    <div class="textWrapper">
                                        <div class="text">${entry.descriptionValue}</div>
                                    </div>
                                </div>
                                <div class="showMoreContainer">
                                    <div class="showMore">Mehr</div>
                                </div>
                            </div>
                        `)}
                    </div>
                    <div class="smallCardContainer">
                        ${this.smallCardSrcArray.map((entry)=>html`
                            <div @click="${()=> this.linkClicked(entry.linkSrc)}" class="smallCard">
                                <div class="mainContainer">
                                    <div class="headerWrapper">
                                        <div class="header">${entry.headerValue}</div>
                                    </div>
                                    <div class="dateWrapper">
                                        <div class="date">${entry.dateValue}</div>
                                    </div>
                                    <div class="textWrapper">
                                        <div class="text">${entry.descriptionValue}</div>
                                    </div>
                                </div>
                                <div class="showMoreContainer">
                                    <div class="showMore">Mehr</div>
                                </div>
                            </div>
                        `)}
                    </div>
                </div>
            </div>
        </div>
    `}
}

customElements.define("news-cards", NewsCards);
import {html} from "lit";
import {LitElement} from "lit";
import {TwoButtonStyles} from "./two-button-styles";

export class TwoButton extends LitElement{
    constructor() {
        super();
        this.contentDescription = "";

        this.buttonLabelOne = "";

        this.buttonLabelTwo = "";

        this.showButtonTwo = true;
    }

    static get properties(){
        return {
            contentDescription: {Type:String},
            buttonLabelOne: {Type:String},
            buttonLabelTwo: {Type:String},
            showButtonTwo: {Type:Boolean}
        }
    }

    static get styles(){
        return TwoButtonStyles;
    }

    dispatchBtnOneClickedEvent(){
        this.dispatchEvent(new CustomEvent("btn-one-clicked"));
    }

    dispatchBtnTwoClickedEvent(){
        this.dispatchEvent(new CustomEvent("btn-two-clicked"));
    }

    render(){
        return html`
            <div class="outerDivOne">
                <div class="buttonContentWrapper">
                        <div class="itemText">
                            ${this.contentDescription}
                        </div>
                        <div class="itemOuterDiv">
                            <button @click="${()=>this.dispatchBtnOneClickedEvent()}" class="infoButton1" 
                                    type="button">${this.buttonLabelOne}</button>
                            ${this.showButtonTwo? html`
                                <button @click="${()=>this.dispatchBtnTwoClickedEvent()}" class="infoButton2"
                                        type="button">${this.buttonLabelTwo}</button>
                            `:``}
                        </div>
                </div>
            </div>
    `}
}

customElements.define("two-button", TwoButton);

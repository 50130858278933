import Swal from "sweetalert2";
export default {
    methods:{
        showConfirmDialogue(title, text){
            return new Promise((resolve) => {
                Swal.fire({
                    title: title,
                    text: text,
                    icon: 'info',
                    iconColor: "rgb(140, 84, 84)",
                    confirmButtonColor: "rgb(29, 29, 29)",
                    confirmButtonText: "Bestätigen",
                    showCancelButton: true,
                    cancelButtonText: "Abbrechen",
                }).then((result) => { // <--
                    if (result.value && result.isConfirmed) {
                        resolve(true);
                    }
                });
            })
        },

        showSuccessMessage(title, message){
            Swal.fire({
                icon: 'success',
                title: title,
                text:message,
                confirmButtonColor: "rgb(29, 29, 29)",
            })
        },
        showErrorMessage(title, message){
            Swal.fire({
                icon: 'error',
                iconColor: "#BE2428",
                text: message,
                title: title,
                confirmButtonColor: "rgb(29, 29, 29)",
            })
        },
        showInfoMessage(title, message){
            Swal.fire({
                icon: 'info',
                iconColor: "rgb(140, 84, 84)",
                title: title,
                html:message,
                confirmButtonColor: "rgb(29, 29, 29)",
            })
        }
    }
}

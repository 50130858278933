
export class SingleButtonStrings{

    static btnText(){
        return "Kostenlos testen";
    }

    static btnText2(){
        return "Beratungstermin vereinbaren";
    }

    static btnText3(){
        return "Mehr zum Funktionsablauf";
    }

}
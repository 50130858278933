import {html} from "lit";
import {LitElement} from "lit";
import {TextIconStyles} from "./text-icon-styles";

export class TextIcon extends LitElement{

    constructor() {
        super();
        this.srcArray = [];
    }

    static get properties(){
        return{
            srcArray: {Type: Array}
        }
    }

    static get styles(){
        return TextIconStyles
    }

    render(){
return html`
        <div class="outerDiv">
            <div class="contentWrapper">
                    ${this.srcArray.map((entry)=> html`
                        <div class="itemOuterDiv">
                            <div class="itemImageDiv">
                                <img class="itemImage" src="${entry.textIconImg}" alt="">
                            </div>
                            <div class="itemTextDiv">
                                <div class="itemHeader">${entry.textIconHeader}</div>
                                <div class="itemText">${entry.textIconDescription}</div>
                            </div>
                        </div>
                    `)}
            </div>
        </div>
    `}
}customElements.define("text-icon", TextIcon)
import {html, LitElement} from "lit";
import {SingleButtonStyles} from "./single-button-styles";

export class SingleButton extends LitElement{

    constructor() {
        super();

        this.btnText = ""
    }

    static get properties(){
        return {
            btnText: {Type:String},
        }
    }


    static get styles(){
        return SingleButtonStyles
    }

    buttonClicked(){
        const customEvent = new CustomEvent("button-clicked",{})
        this.dispatchEvent(customEvent);
    }

    render() {
        return html`
            <button class="btn" @click="${()=> this.buttonClicked()}">${this.btnText}</button>
        `
    }
}

customElements.define("single-button", SingleButton)
<template>
  <div class="video-container">
    <video :muted="muted" :loop="loop" :autoplay="autoplay" :controls="controls" :src="this.videoParams.videoSrc" :poster="this.videoParams.imgPoster"></video>
  </div>

</template>

<script>
export default {
  name: "video-view",
  props: {
    videoParams: Array,
    controls: Boolean,
    autoplay: Boolean,
    loop: Boolean,
    muted: Boolean,
  }
}
</script>

<style scoped>

.video-container{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

video{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 2px;
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 10%);
}


@media only screen and (max-width: 1100px) {
  video{
    object-fit: contain;
  }

  .video-container{
    height: 100%;
  }
}

</style>